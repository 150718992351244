import { useState } from "react"
import {
  FieldConfig,
  FieldValue,
  GroupFieldValue,
  MultiGroupFieldValue,
  ValidationErrors,
} from "./types"
import { useFormContext } from "./context"
import {
  setFieldValue,
  setFieldValues,
  setFormState,
  setError,
  clearError,
  setFormErrors,
} from "./actions"
import { getVisibleErrors, validateField } from "./validations"
import Label from "@common/v2/label"
import ErrorMessage from "./errorMessage"
import BaseField from "@common/v2/form/baseField"
import GroupField from "@common/v2/form/groupField"
import MultiGroupField from "@common/v2/form/multiGroupField"
import {
  flattenObject,
  getValue,
  unflattenObject,
} from "@common/lib/flattenUnflattenObjects"
import { isNullOrEmpty } from "@common/lib/util"

export default function Field({
  id,
  config,
  onChange,
  value,
  isGroupField = false,
}: {
  id: string
  config: FieldConfig
  onChange?: (id: string, value: FieldValue) => void
  value?: FieldValue
  isGroupField?: boolean
}) {
  const { state, dispatch, size, onStateChange, debouncedStateChange } =
    useFormContext()
  const [isFocused, setIsFocused] = useState(false)

  if (isNullOrEmpty(config)) return

  const getInitValue = () => {
    const unflattened = unflattenObject(state.formState)
    const stateValue = getValue(unflattened, id)
    return id in state.formState || stateValue !== undefined ? stateValue : value
  }

  const handleBaseChange = (newValue: FieldValue) => {
    onChange?.(id, newValue)
    /* if (!isGroupField) */
    // Note: Removed the isGrouped condition so we set formState for individual fields for all the inputs, group and multigroup component
    dispatch(setFieldValue(id, newValue))

    // field validation
    const validationErrors = { ...state.errors }
    const validationError = validateField(config, newValue)
    if (validationError) {
      validationErrors[id] = validationError
      dispatch(setError(id, validationError))
    } else {
      delete validationErrors[id]
      dispatch(clearError(id))
    }
    // call form's onStateChange
    const formState = unflattenObject({ ...state.formState, [id]: newValue })
    const errors = getVisibleErrors({ ...state, errors: validationErrors })
    if (config.debounce) {
      debouncedStateChange?.(formState, errors)
    } else {
      onStateChange?.(formState, errors)
    }
  }

  const handleGroupChange = (id: string, value: GroupFieldValue) => {
    const flattened = flattenObject({ [id]: value })
    dispatch(setFieldValues(flattened))
  }

  const handleMultiGroupChange = (
    id: string,
    value: MultiGroupFieldValue,
    errors: ValidationErrors,
    isAction?: boolean
  ) => {
    if (isAction) {
      // we will do this when a group is removed , added or reset
      // When field value changes it will be handled by handleBaseChange
      const formState = { ...state.formState }
      formState[id] = value
      const unflattenedState = unflattenObject(formState)
      const validationErrors = { ...state.errors, ...errors }
      dispatch(setFormState(flattenObject(unflattenedState)))
      dispatch(setFormErrors(validationErrors))
      onChange?.(id, value)
      // Note: This is required here since base onChange doesn't trigger whe we reset multigroup field
      const newErrors = getVisibleErrors({ ...state, errors: validationErrors })
      onStateChange?.(unflattenedState, newErrors)
    } 
  }

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  const fieldValue = getInitValue()

  const behaviorState =
    state.behaviorState && id in state.behaviorState ? state.behaviorState[id] : {}
  const invisible = behaviorState?.invisible == true
  const disabled = behaviorState?.disabled == true

  if (invisible) return undefined

  return (
    <>
      <Label
        key={id}
        id={id}
        {...(config?.label || {})}
        required={config?.validation?.required}
      >
        {renderField(id, config)}
        <ErrorMessage id={id} isFocused={isFocused} showItemsMsg={true} />
      </Label>
    </>
  )

  function renderField(id: string, config: FieldConfig) {
    switch (config.element) {
      case "group":
        return (
          <GroupField
            id={id}
            config={config}
            onChange={handleGroupChange}
            value={fieldValue}
          />
        )
      case "multi_group":
        return (
          <MultiGroupField
            id={id}
            config={config}
            onChange={handleMultiGroupChange}
            value={fieldValue}
          />
        )
      default:
        return (
          <BaseField
            id={id}
            config={config}
            onChange={handleBaseChange}
            value={fieldValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            size={size}
          />
        )
    }
  }
}
